import { LookupService } from '../../share/services/lookup/lookup.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEdit, faTrash, faChain, faFile, faEye } from '@fortawesome/free-solid-svg-icons';
import { globalPreferences } from "../../user-storage";
import { CommonService } from '../../share/services/common/common.service';
import { AcceptRejectConfirmationModalComponent } from 'src/app/admin/company/accept-reject-confirmation-modal/accept-reject-confirmation-modal.component';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { StorageKeys } from 'src/app/share/enums/storage.enum';

@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrl: './document-type-list.component.scss',
})
export class DocumentTypeListComponent implements OnInit {
  @Input() tabvalue: any;
  @Input() isDocumentType: any;
  @Output() viewDoc: EventEmitter<boolean> = new EventEmitter<boolean>();
  documenttypelist: any;
  selfDetails: any = {};
  errorMessage: string;
  documentType: any = null;
  formType: string = 'edit';
  fileExtensionData: any = {};
  enabledExtensions: any = {};
  verifyLoader: boolean = false;
  isToaster:boolean=false;
  modal: any;
  uploadErrorMessage :string ="";
  public readonly faEdit = faEdit;
  public readonly faTrash = faTrash;
  public readonly faChain = faChain;
  public readonly faFile = faFile;
  public readonly faEye = faEye;
  public readonly fatrash = faTrash;
  hasEditAccess: boolean;

  constructor(
    private lookupService: LookupService,
    private commonService: CommonService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.selfDetails = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    this.getDocumentTypeList();
    if (this.documentType?._id) {
      this.onEditDocumentType(this.documentType);
    }
    globalPreferences(this.commonService, "fileExtensions", 'returnObject', (err: any, pref: any) => {
      this.fileExtensionData = pref;
      if (this.fileExtensionData) {
        for (let pref of this.fileExtensionData.allowedFileTypes) {
          pref.availableExtensions = [...pref.enabledExtensions];
        }
      }
    })
  }

  getDocumentTypeList() {
    this.isDocumentType = false;
    this.lookupService.getDocumentTypeList()?.subscribe({
      next: (res) => {
        if (res?.success) {
          this.documenttypelist = res.data;
        }
      },
      error: (err) => {
        this.errorMessage = err.error?.error?.message;
      },
    });
  }

  onCloseIconClick(value: any) {
    document.body.classList.remove('panel-open');
    if (value) {
      this.getDocumentTypeList();
    }
    this.isDocumentType = false;
    this.documentType = null;
    this.formType = 'edit';
  }

  onEditDocumentType(item: any, formType: string = 'edit') {
    this.hasEditAccess = this.checkEditAccess(item);
    if (formType == 'view') {
      this.viewDoc.emit(item);
    }
    else if (formType == 'DELETE') {
      this.isDocumentType = false;
      this.deleteContractType(item);
    }
    else {
      document.body.classList.add('panel-open');
      this.isDocumentType = true;
      this.documentType = item;
      this.formType = formType;
      this.enabledExtensions = JSON.parse(JSON.stringify((this.fileExtensionData || {})));
      if (this.enabledExtensions && item && item.allowedExtensions?.length) {
        for (let pref of this.enabledExtensions.allowedFileTypes) {
          pref.enabledExtensions = [];
          pref.availableExtensions.forEach((ext: string)=>{
            if(item.allowedExtensions.includes(ext)){
              pref.enabledExtensions.push(ext);
            }
          })
        }
      }
    }
  }
  checkEditAccess(docType: any){
    if (!this.commonService.hasAllowedAction()) {
      return false;
    }
    return docType?.visibleTo.includes(this.selfDetails?.company);
  }

  deleteContractType(item: any){
    this.lookupService.deleteContractType(item._id).subscribe({
      next: (res) => {
    },
      error: (err) => {
        this.errorMessage = err.error?.error?.message;
      },
    });

    let dialogRef = this.modal?.open(AcceptRejectConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
    if (dialogRef?.componentInstance) {
      dialogRef.componentInstance.roleId = item._id;
      dialogRef.componentInstance.isToast = false;
      dialogRef.componentInstance.message = `Do you want to delete this contract type list`;
      dialogRef.result.then(
        (result: any) => {

        },
        (reason: any) => {

        }
      );
    }
  }

  getMetaDataFields(assignedLookups: any = []) {
    let fields = [];
    for (let field of assignedLookups) {
      fields.push(field.label);
    }
    return fields.length ? fields.join(",") : 'NA'
  }

  updateExtensions(event: any) {
    let allowedExtensions: any[] = [];
    if (event) {
      for (let pref of event.allowedFileTypes) {
        if (pref.status === 'Enabled') {
          allowedExtensions = allowedExtensions.concat(pref.enabledExtensions);
        }
      }
    }
    this.updateServiceRequest({ updateType: 'extensions', allowedExtensions }, 'allowedExtensions');
  }

  updateRegex(event: any) {
    if (!event?.customLrFormat?.length) {
      return;
    }
    let payload: any = {
      updateType: 'formatDetails',
      formatDetails: {
        numberFormat: event?.customLrFormat,
        sampleFormat: event?.sampleId,
        allowedRegex: event?.regexFormat,
        formatType: event?.formatType
      },
      enableBarcodeScanning : event?.enableBarcodeScanning,
      deskew: event?.deskew
    };
    this.updateServiceRequest(payload, 'allowedExtensions');
  }
  updateServiceRequest(payload: any, key: string) {
    this.verifyLoader = true;
    this.lookupService.editDocumentType(payload, this.documentType?._id).subscribe({
      next: (res) => {
        this.verifyLoader = false;
        this.documentType[key] = payload[key];
        if (res) {
          if(this.formType === 'editExt'){
            this.uploadErrorMessage = 'File Extensions updated successfully!';
            this.isToaster = true;
          }
          if (this.formType === 'dynamicRegex') {
            if (this.documentType?.formatDetails?.numberFormat.length == 0) {
              this.uploadErrorMessage = 'Document number format created successfully!';
            } else {
              this.uploadErrorMessage = 'Document number format updated successfully!';
            }
            this.isToaster = true;
          }
          this.onCloseIconClick(true);
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
        this.verifyLoader = false;
      }
    });
  }

  onSubmit(event: any) {
    if (event.success) {
      this.isToaster = true;
      this.uploadErrorMessage = event.message;
    }
  }

  closeToster(event: any){
    this.isToaster  = false;
    this.uploadErrorMessage = '';
  }
}
