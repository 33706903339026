<section class="editDocument__container">
  <header>
    <app-offcanvas-header paddingClass="paddingClass" header="Format Rules"
      (closeClicked)="onCloseIconClick(false)"></app-offcanvas-header>
  </header>
  <div class="container p-4">
    <div class="d-flex justify-content-between">
      <p>
        <b>{{ envType==appType.clms ? 'Contract Number Format' : 'Document Number Format' }}: {{ sampleId || "NA"
          }}</b>
      </p>
    </div>

    <div class="row" *ngIf="!isContractFormat">
      <div class="col-6">
        <div class="form-group">
          <label for="formatType">Format Type</label>
          <select id="formatType" class="dropdownOptions"
            (change)="onSelectFormatType($event)" [formControl]="formatType">
            <option [value]="item" *ngFor="let item of formatTypes"> {{item}} </option>
          </select>
        </div>
      </div>
    </div>

    <form class="pt-2" [formGroup]="dynamicForm">
      <div *ngFor="let control of customLrFormat; let i = index">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="typeSelect{{ i }}">Select Type</label>
              <select id="typeSelect{{ i }}" class="dropdownOptions " required (change)="onSelectType(control, $event)">
                <option value="" hidden="">Select Type</option>
                <option *ngFor=" let component of components;let componentIndex = index" [value]="componentIndex"
                  [selected]="control.formatType == component.formatType">
                  {{ component.formatType === 'RS' ? 'Random String' : component.formatType }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4" *ngIf="control.formatType === 'Static'">
            <div class="form-group">
              <label for="Separator{{ i }}">Enter Static Value</label>
              <input type="text" class="form-control" placeholder="Static Value" [value]="control.value"
                [(ngModel)]="control.value" [ngModelOptions]="{ standalone: true }" (keyup)="formatDocId()">
            </div>
          </div>
          <div class="col-md-4" *ngIf="control.formatType === 'RS'">
            <div class="form-group">
              <label for="Separator{{ i }}">{{this.formatType.value == 'Manual'?'Length(If Fixed)':'Length'}}</label>
              <input type="number" min="1" class="form-control" [value]="control.value"
                placeholder="{{this.formatType.value == 'Manual'?'(optional)':'Length'}}" [(ngModel)]="control.len"
                [ngModelOptions]="{ standalone: true }" (keyup)="formatDocId()">
            </div>
          </div>
          <div class="col-md-2" *ngIf="control.formatType === 'Sequence'">
            <div class="form-group">
              <label for="Separator{{ i }}">Length</label>
              <input type="number" class="form-control" min="1" [value]="control.value" placeholder="Length"
                [(ngModel)]="control.len" [ngModelOptions]="{ standalone: true }" (keyup)="formatDocId()" required>
            </div>
          </div>
          <div class="col-md-3" *ngIf="control.formatType === 'Sequence'">
            <div class="form-group">
              <label for="Separator{{ i }}">Start From</label>
              <input type="text" class="form-control" placeholder="Start From" [value]="control.startAt"
                [(ngModel)]="control.startAt" [ngModelOptions]="{ standalone: true }" (keyup)="formatDocId()">
            </div>
          </div>
          <div class="col-md-4" *ngIf="control.formatType === 'Numeric'">
            <div class="form-group">
              <label for="Separator{{ i }}">Length(If Fixed)</label>
              <input type="number" class="form-control" [value]="control.value" placeholder="(optional)"
                [(ngModel)]="control.len" [ngModelOptions]="{ standalone: true }" (keyup)="formatDocId()">
            </div>
          </div>
          <div [ngClass]="control.formatType === 'Sequence' ? 'col-md-2' : 'col-md-3'">
            <div class="form-group">
              <label for="Separator{{ i }}">Separator</label>
              <input type="text" class="form-control" placeholder="Optional" [value]="control.separator || ''"
                [(ngModel)]="control.separator" [ngModelOptions]="{ standalone: true }" (keyup)="formatDocId()">
            </div>
          </div>
          <div class="col-md-1" *ngIf="i !== (customLrFormat.length - 1)">
            <button class="btn_primary lr-number-addButton" type="button" (click)="deleteFormat(i)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
          <div class="col-md-1" *ngIf="i === (customLrFormat.length - 1)">
            <button class="btn_primary lr-number-addButton" type="button" (click)="addFormArrayGroup()">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="envType===appType.dams" class="mt-4">
      <label class="barcodelabel">Barcode Scanning</label>
      <app-common-toggle-switch (onChange)="handleBarcodeScanning($event)" [checked]="enableBarcodeScanning"></app-common-toggle-switch>
    </div>
    <div *ngIf="envType===appType.dams" class="mt-4">
      <label class="barcodelabel">Deskew Document</label>
      <app-common-toggle-switch (onChange)="handleDeskewDocument($event)" [checked]="deskew"></app-common-toggle-switch>
    </div>
  </div>

  <div class="editDocument__footer">
    <div class="offCanvas__footer">
      <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"
        (clickEvent)="onCloseIconClick(false)"></app-common-button>
      <app-common-button *ngIf="commonService.orgLock() && commonService.hasAllowedAction() && (envType !== appType.dams || hasEditAccess)"
      buttonClass="smallFilledButton" buttonLabel="Submit" [loadingIcon]="verifyLoader"
        (clickEvent)="saveFormat()" [disabled]="isValidFormat() || isValidAutoFormat()"></app-common-button>
    </div>
    <div *ngIf="errorMsg" class="text-danger text-center">
      {{ errorMsg }}
    </div>

  </div>
</section>